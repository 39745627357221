import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import React from 'react';

import SectionFeaturesDefault from './SectionFeaturesDefault';
import SectionFeaturesStyle1 from './SectionFeaturesStyle1';

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionFeaturesCustom = props => {
  const { sectionName } = props;

  if (/^features-style-1/.test(sectionName)) {
    return <SectionFeaturesStyle1 {...props} />;
  }

  return <SectionFeaturesDefault {...props} />;
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFeaturesCustom.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionFeaturesCustom.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionFeaturesCustom;
