import loadable from '@loadable/component';
import React from 'react';

import { bool, object } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import SectionColumnsCustom from '../PageBuilder/SectionBuilder/SectionColumnsCustom';
import SectionFeaturesCustom from '../PageBuilder/SectionBuilder/SectionFeaturesCustom';
import SectionHeroCustom from '../PageBuilder/SectionBuilder/SectionHeroCustom/SectionHeroCustom';
import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  return (
    <PageBuilder
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      options={{
        sectionComponents: {
          features: {
            component: SectionFeaturesCustom,
          },
          columns: {
            component: SectionColumnsCustom,
          },
          hero: {
            component: SectionHeroCustom,
          },
        },
      }}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
